import React from 'react'
import classNames from 'classnames'
import cl from '../classnames.scss'
import {GroupCardProps} from './interfaces'
import s from './group-card.scss'
import {JoinButton} from './join-button'
import {GroupImage} from './group-image'

export const GroupCard = ({
  className,
  title,
  isPrivate,
  image,
  joinGroup,
  membersCount,
  updatesCount,
  t,
}: GroupCardProps) => {
  const hasUpdatesOrLoading = updatesCount !== 0

  return (
    <div className={classNames(s.root, className)}>
      <div className={classNames(s.privacy, cl.evTextFont)}>
        {isPrivate ? t('groups.privacy.private') : t('groups.privacy.public')}
      </div>
      <div className={classNames(s.card, {[s.noImage]: !image})}>
        {image ? <GroupImage image={image} className={s.image} /> : null}
        <div className={s.description}>
          <h4 className={classNames(s.title, cl.evTextFont)}>{title}</h4>
          {membersCount || hasUpdatesOrLoading ? (
            <div className={classNames(s.activity, cl.evTextFont)}>
              {membersCount
                ? membersCount > 1
                  ? t('groups.members.plural', {membersCount})
                  : t('groups.members.singular')
                : null}
              {membersCount && hasUpdatesOrLoading ? <> &#183; </> : ''}
              {hasUpdatesOrLoading
                ? updatesCount === 1
                  ? t('groups.activity.singular')
                  : t('groups.activity.plural', {updatesCount: updatesCount || '-'})
                : null}
            </div>
          ) : null}
        </div>
        <div className={s.cta}>
          <JoinButton className={s.button} onClick={() => joinGroup()}>
            {t('groups.joinButton')}
          </JoinButton>
        </div>
      </div>
    </div>
  )
}
